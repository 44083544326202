import React, { useState } from 'react';
import { Button, Form, Message, Placeholder, Header, Image, Grid, Menu, Icon, Divider, Container } from 'semantic-ui-react';
import { useLocation, useNavigate } from "react-router-dom";


const MobileMenu: React.FC<any> = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    return (<Container>
        <Button
            fluid
            color='orange'
            content='Nastavení úložiště'
            onClick={() => navigate('/Company/StorageDestinationSettings')}
            style={{ marginTop: '1em', marginBottom: '1em' }}
        />

        {false && <>
            <Button
                fluid
                color='orange'
                content='Register'
                onClick={() => navigate('/CreateAccount')}
                style={{ marginTop: '1em', marginBottom: '1em' }}
            />

            <Button
                fluid
                color='orange'
                content='Change password'
                onClick={() => navigate('/Account/PasswordChange')}
                style={{ marginTop: '1em', marginBottom: '1em' }}
            />

            <Button
                fluid
                color='orange'
                content='Forgot password?'
                onClick={() => navigate('/Account/ForgottenPassword/Request')}
                style={{ marginTop: '1em', marginBottom: '1em' }}
            />

            <Button
                fluid
                color='orange'
                content='Login'
                onClick={() => navigate('/Login')}
                style={{ marginTop: '1em', marginBottom: '1em' }}
            />
        </>
        }

    </Container>);
}

export default MobileMenu;