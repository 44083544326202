import React, { RefObject, VideoHTMLAttributes, useState, useEffect, useCallback, useLayoutEffect, useRef } from 'react';

import './BarcodeScanner.css';

import { Button, Container, Form, Grid, Header, Segment } from 'semantic-ui-react';

import Quagga, { QuaggaJSResultObject } from '@ericblade/quagga2';
import { isIOS } from 'react-device-detect';

function getMedian(arr: Array<any>) {
    arr.sort((a, b) => a - b);
    const half = Math.floor(arr.length / 2);
    if (arr.length % 2 === 1) {
        return arr[half];
    }
    return (arr[half - 1] + arr[half]) / 2;
}

function getMedianOfCodeErrors(decodedCodes: any) {
    const errors = decodedCodes.flatMap((x: { error: any; }) => x.error);
    const medianOfErrors = getMedian(errors);
    return medianOfErrors;
}

const defaultConstraints = {
    width: 640,
    height: 480,
};

const defaultLocatorSettings = {
    patchSize: 'medium',
    halfSample: true,
    willReadFrequently: true,
};

const defaultDecoders = ['ean_reader'];

export type BarcodeScannerProps = {
    showButtons: boolean,

    onConfirm: Function,
};

export type BarcodeScannerState = {
    scannedBarcode: string | null
};

const BarcodeScanner: React.FC<BarcodeScannerProps> = (props) => {
    const noBarcodeValue: string = '--------------';
    const barcodeRef = useRef<HTMLDivElement>(null);

    const [manualInput, setManualInput] = useState<boolean>(false);
    const [scannerState, setScannerState] = useState<BarcodeScannerState>({
        scannedBarcode: noBarcodeValue
    });

    const errorCheck = useCallback((result: QuaggaJSResultObject) => {
        const err = getMedianOfCodeErrors(result.codeResult.decodedCodes);
        // if Quagga is at least 75% certain that it read correctly, then accept the code.
        if (err < 0.25) {
            setScannerState(prevState => ({
                ...prevState,
                scannedBarcode: result.codeResult.code
            }));
        }
    }, [setScannerState]);

    const handleProcessed = (result: QuaggaJSResultObject) => {
        var drawingCtx = Quagga.canvas.ctx.overlay,
            drawingCanvas = Quagga.canvas.dom.overlay;

        if (result) {
            if (result.boxes) {
                drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width") ?? "0"), parseInt(drawingCanvas.getAttribute("height") ?? "0"));
                result.boxes.filter(function (box) {
                    return box !== result.box;
                }).forEach(function (box) {
                    Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: "green", lineWidth: 2 });
                });
            }

            if (result.box) {
                // transform box
                const line = result.line;
                const fakeBox = result.box;

                const resheight = fakeBox[0][1] - fakeBox[1][1];
                //const reswidth = fakeBox[0][0] - fakeBox[2][0];

                const resultBox = [
                    [line[0].x, line[0].y + resheight / 2],
                    [line[1].x, line[1].y + resheight / 2],
                    [line[1].x, line[1].y + -1 * resheight / 2],
                    [line[0].x, line[0].y + -1 * resheight / 2]
                ];
                //Quagga.ImageDebug.drawPath(resultBox, { x: 0, y: 1 }, drawingCtx, { color: "green", lineWidth: 2 });
            }

            if (result.codeResult && result.codeResult.code) {
                Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: 'red', lineWidth: 3 });
            }
        }
    }

    const refreshCamera = () => {
        Quagga.stop();

        setManualInput(false);
        setScannerState(prevState => ({
            ...prevState,
            scannedBarcode: noBarcodeValue
        }));

        setTimeout(() => startQuagga(), 10);
    }

    const orientationChanged = () => {
        refreshCamera();
    }

    const quaggaButtons = () => {
        if (props.showButtons) {
            return (<div className={'quagga-buttons'}>
                <Button className='refreshButton' circular icon='refresh' onClick={refreshCamera} />
            </div>);
        }
    }

    const logKey = (e: KeyboardEvent) => {
        if (e.isComposing || e.keyCode === 229) {
            return;
        }

        if (e.key === "Enter") {
            if (scannerState.scannedBarcode != noBarcodeValue) {
                props.onConfirm(scannerState.scannedBarcode)
            }


            return;
        }

        const isLetter = (e.key >= "a" && e.key <= "z") || (e.key >= "A" && e.key <= "Z");
        const isNumber = (e.key >= "0" && e.key <= "9");
        if (e.key.length === 1 && isLetter || isNumber) {
            setScannerState(prevState => ({
                ...prevState,
                scannedBarcode: scannerState.scannedBarcode == noBarcodeValue ? e.key : scannerState.scannedBarcode + e.key
            }));
        }
    }

    const iosConstraints = {
        aspectRatio: { ideal: 1 },
        width: 320,
        height: 400,
        facingMode: 'environment'
    };

    const normalConstraints = {
        aspectRatio: { ideal: 1 },
        facingMode: 'environment'
    };

    useLayoutEffect(() => {
        // if this component gets unmounted in the same tick that it is mounted, then all hell breaks loose,
        // so we need to wait 1 tick before calling init().  I'm not sure how to fix that, if it's even possible,
        // given the asynchronous nature of the camera functions, the non asynchronous nature of React, and just how
        // awful browsers are at dealing with cameras.
        let ignoreStart = false;
        const init = async () => {
            // wait for one tick to see if we get unmounted before we can possibly even begin cleanup
            await new Promise((resolve) => setTimeout(resolve, 50));
            if (ignoreStart) {
                return;
            }
            // begin scanner initialization
            await startQuagga();

            Quagga.onDetected(errorCheck);
        };
        init();
        // cleanup by turning off the camera and any listeners
        return () => {
            ignoreStart = true;
            Quagga.stop();
            Quagga.offDetected(errorCheck);
            Quagga.offProcessed(handleProcessed);
        };
    }, [barcodeRef, errorCheck]);

    const startQuagga = async () => {
        await Quagga.init({
            inputStream: {
                name: 'Live',
                type: 'LiveStream',
                constraints: isIOS ? iosConstraints : normalConstraints,
                target: barcodeRef.current as Element
            },
            decoder: {
                readers: ['code_128_reader'],
                multiple: false
            },
            locator: {
                patchSize: 'medium',
                halfSample: true
            },
            locate: false
        }, async (err) => {
            Quagga.onProcessed(handleProcessed);

            if (err) {
                return console.error('Error starting Quagga:', err);
            }
            if (barcodeRef && barcodeRef.current) {
                await Quagga.start();
            }
        });
    };

    return (<div style={{ height: '100%' }}>
        <Segment.Group>
            <Segment color='blue'>
                Namiřte kameru na čárový kód a potvrďte tlačítkem
            </Segment>
            <Segment inverted onClick={() => setManualInput(true)}>
                {!manualInput && <>{scannerState.scannedBarcode}</>}
                {manualInput && <Form.Input transparent inverted onChange={(e, data) => setScannerState(prevState => ({
                    ...prevState,
                    scannedBarcode: data.value
                }))} value={scannerState.scannedBarcode} >
                    <input style={{ textAlign: 'center', width: '100%' }} /></Form.Input>}
            </Segment>
        </Segment.Group>

        <div id="barcodeVideo" className="viewport" ref={barcodeRef} style={{ height: 'calc(100% - 80px - 120px)' }}>
            <div style={{ position: 'relative', height: 'auto' }}>
                <video src=""></video>
                <canvas className="drawingBuffer"></canvas>
                {quaggaButtons()}
            </div>
        </div>


        <div className='barcodeScanner-buttonbox'>
            <Button size='massive' circular icon='check' color='green' disabled={!scannerState.scannedBarcode || scannerState.scannedBarcode === noBarcodeValue}
                onClick={() => props.onConfirm(scannerState.scannedBarcode)} />
        </div>
    </div>
    );
}

export default BarcodeScanner;