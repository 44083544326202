import axios, { AxiosResponse, AxiosError, AxiosRequestConfig, AxiosInstance } from "axios";
import UserService from "./UserService";

declare module 'axios' {
    export interface AxiosRequestConfig {
        _retry?: boolean;
    }
}

import { ApiRequestOptions } from './generatedApi/core/ApiRequestOptions';
import { AppClient, AppUser, AuthenticateResponse, BaseHttpRequest, CancelablePromise, OpenAPIConfig } from "./generatedApi";
import { request as __request } from './generatedApi/core/request';

export function addInterceptors(axiosInstance: AxiosInstance) {
    axiosInstance.interceptors.request.use(
        (config) => {
            const token = UserService.getLocalAccessToken();

            if (token) {
                config.headers["Authorization"] = 'Bearer ' + token;
            }
            return config;
        },
        (error: AxiosError) => {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (res: AxiosResponse) => {
            return res;
        },
        async (err: AxiosError) => {
            const originalConfig: AxiosRequestConfig = err.config!;

            if (originalConfig.url !== "api/Users/authorization" && err.response &&
                err.response.headers["www-authenticate"] && err.response.headers["www-authenticate"].indexOf('The token expired') !== -1) {
                console.warn('Token Expired!');

                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;

                    try {
                        const rs = await axiosInstance.post<AuthenticateResponse>("api/Users/refresh-token");
                        UserService.updateLocalAccessToken(rs.data);

                        const user = await axiosInstance.get<AppUser>("api/User/")
                        UserService.setUser(user.data);

                        return axiosInstance(originalConfig);
                    } catch (_error) {
                        UserService.logout();
                        window.location.replace('/');


                        return Promise.reject(_error);
                    }
                }
            }

            return Promise.reject(err);
        }
    );
}

export class AxiosHttpRequestWithAuth extends BaseHttpRequest {
    instance = axios.create({
        baseURL: "/",
        headers: {
            "Content-Type": "application/json",
        },
    });

    constructor(config: OpenAPIConfig) {
        super(config);

        addInterceptors(this.instance);
    }

    public override request<T>(options: ApiRequestOptions): CancelablePromise<T> {
        return __request(this.config, options, this.instance);
    }
}

const instance = new AppClient({}, AxiosHttpRequestWithAuth)

export default instance;