import { AppUser, AuthenticateResponse } from "./generatedApi";
import {useNavigate} from "react-router-dom";


class UserService {
    getLocalRefreshToken(): string | null {
        const storedData = localStorage.getItem("authenticateResponse")

        if (storedData !== null) {
            const loginResponse = JSON.parse(storedData);
            return loginResponse?.refreshToken;
        }

        return null;
    }

    getLocalAccessToken(): string | null {
        const storedData = localStorage.getItem("authenticateResponse")

        if (storedData !== null) {
            const loginResponse = JSON.parse(storedData);
            return loginResponse?.accessToken;
        }

        return null;
    }

    updateLocalAccessToken(loginResponse: AuthenticateResponse) {
        localStorage.setItem("authenticateResponse", JSON.stringify(loginResponse));
    }

    getUser(): AppUser | null {
        const storedData = localStorage.getItem("user")

        if (storedData !== null) {
            const user = JSON.parse(storedData);
            return user;
        }

        return null;
    }

    setUser(user: AppUser) {
        localStorage.setItem("user", JSON.stringify(user));
    }

    logout() {
        localStorage.removeItem("user");
        localStorage.removeItem("authenticateResponse");
    }
}

export default new UserService();