import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Button, Form, Segment, Placeholder, Header, Image, Grid, Label, Container, Icon, Divider } from 'semantic-ui-react';

import axios, { AxiosResponse, AxiosError } from 'axios';
import axiosInstance from '../../services/api';

import eventBus from '../../helpers/EventBus';

import UserService from '../../services/UserService';
import { useAuth } from '../../hooks/useAuth';

const AccountIndex: React.FC<any> = (props) => {
    const [imageError, setImageError] = useState(false);

    const navigate = useNavigate();
    const authContext = useAuth();

    const buttonDividerStyle = { marginBottom: '.25em' };

    async function handleLogout() {
        try {
            await axiosInstance.users.postApiUsersRevokeToken();
        } catch (e) {
            console.warn('failed to logout properly and revoke refresh token');
        }

        authContext?.logout();
    }

    return (<Container className='gradientContainer noStandaloneOffset'>
        <Helmet>
            <meta name="theme-color" content="#000000" />
        </Helmet>

        <Grid textAlign='center' verticalAlign='middle' style={{ height: '100%', paddingBottom: '80px' }}>
            <Grid.Row>
                <Grid.Column verticalAlign='top'>
                    <Container>
                        <Segment>
                            {!imageError ?
                                <Image size='small' circular centered onError={() => setImageError(true)} style={{ height: 150, width: 150, border: 'solid .5em #eb8000' }}
                                    src='shttps://media.licdn.com/dms/image/C4D03AQH6BM0laidSyA/profile-displayphoto-shrink_800_800/0/1554979003092?e=2147483647&v=beta&t=aFMgp_s_shN2f1wdwsPaTkc0NBR0sXepwM8PpgCgMIE' />
                                :
                                <Container style={{ position: 'relative' }}>
                                    <Placeholder style={{ height: 150, width: 150, margin: 'auto', borderRadius: '50%', animation: 'none', border: 'solid .5em #eb8000' }}>
                                        <Placeholder.Image square />
                                    </Placeholder>
                                    <Header style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', margin: '0', fontSize: '3em', fontWeight: '100' }}>
                                        {authContext?.user?.firstName && authContext.user.firstName[0]}{authContext?.user?.lastName && authContext.user.lastName[0]}
                                    </Header>
                                </Container>
                            }
                            <Header>{authContext?.user?.firstName} {authContext?.user?.lastName}</Header>

                            <Container style={{ position: 'absolute', bottom: '-2.5em', left: '0px', display: 'none' }} fluid>
                                <Segment basic>
                                    <Button fluid size='large' >Upravit údaje</Button>
                                </Segment>
                            </Container>
                        </Segment>
                    </Container>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column verticalAlign='bottom'>
                    <Container>
                        <Segment>
                            {authContext?.user?.ownedCompany && <>
                                <Button fluid size='large' disabled>Správa zaměstnanců</Button>
                                <Divider fitted hidden style={buttonDividerStyle} />
                            </>
                            }

                            <Button fluid size='large' onClick={() => navigate('PasswordChange')}>Změna hesla</Button>
                            <Divider fitted hidden style={buttonDividerStyle} />

                            <Button primary fluid size='large' onClick={async () => handleLogout()}>Odhlásit se</Button>
                        </Segment>
                    </Container>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Container>);
}

export default AccountIndex;