import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, List, Container, Image, Pagination, Grid, Search, Divider, Card, Header, CardContent, Icon, Form, Dropdown, DropdownItemProps } from 'semantic-ui-react';

import instance from '../../services/api';
import { EvidenceEntryPagedListResponse, StorageDestinationTypes } from '../../services/generatedApi';
import debounce from 'lodash.debounce';
import { useNavigate } from 'react-router-dom';

const AccountForgottenPasswordRequest: React.FC<any> = (props) => {
    const navigate = useNavigate();

    return (
        <Container className='gradientContainer noStandaloneOffset' style={{ borderRadius: '1em', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Container>
                <Divider hidden />

                <Header inverted size='huge' textAlign='left' style={{ marginBottom: '0', marginTop: '0', fontSize: '3em', textShadow: '0px 0px 6px rgba(0,0,0,0.4)', marginLeft: '.5em' }}>
                    Vytvoření účtu
                    <Header.Subheader style={{ textShadow: '0 0' }}>

                    </Header.Subheader>
                </Header>
            </Container>

            <Container fluid style={{
                height: 'auto', backgroundColor: '#E6E1E1', flexGrow: '1', display: 'flex', flexDirection: 'column',
                borderRadius: '2em', paddingBottom: '80px', overflow: 'auto'
            }} >
                <Container textAlign='center'>
                    <Divider hidden />
                    <Icon.Group size='massive'>
                        <Icon name='shield alternate' color='black' />
                        <Icon name='lock' size='mini' color='black' />
                    </Icon.Group>

                    <Header>
                        Zapomněli jste svoje heslo?
                        <Header.Subheader>
                            Nic se neděje, níže zadejte svůj email a my Vám obratem zašleme odkaz pro obnovení hesla 
                        </Header.Subheader>
                    </Header>

                    <Form>
                        <Form.Input fluid placeholder='vase-emailova@adresa.cz' type='email'/>

                        <Form.Button fluid icon='save outline' primary content='Obnovit heslo' />
                    </Form>
                </Container>

                <Divider hidden />
            </Container>
        </Container >);
}

export default AccountForgottenPasswordRequest;