import React, { RefObject } from 'react';

import './PhotoCreation.css';

import { Button, Container, Segment } from 'semantic-ui-react';

import Webcam from 'react-webcam';

export type PhotoCreationProps = {
    barcode: string,
    disabled?: boolean,
    onPictureTaken: Function,
    onCancel: Function,
};

export type PhotoCreationState = {
    pictureSaving: boolean,
    cameraReady: boolean
};

export default class PhotoCreation extends React.Component<PhotoCreationProps, PhotoCreationState> {

    private cameraRef: RefObject<Webcam> = React.createRef();

    constructor(props: PhotoCreationProps) {
        super(props);

        this.state = {
            pictureSaving: false,
            cameraReady: false
        };
    }

    pictureTaken() {
        this.setState({ pictureSaving: true });

        // makes sure animation is started before screenshot taking occurs
        setTimeout(() => {
            const image = this.cameraRef.current?.getScreenshot();
            // TODO: if image is null, show error and go cancel
            if (this.props.onPictureTaken) {
                this.props.onPictureTaken(image);
            }

            this.setState({ pictureSaving: false });
        }, 25);
    }

    onUserMedia() {
        this.setState({ cameraReady: true });
    }

    render() {
        const { onPictureTaken, onCancel, ...props } = this.props;

        return (<div {...props} style={{ height: '100%' }}>
            <div className='fullsize' style={{ height: '100%' }}>
                <Segment.Group>
                    <Segment color='blue'>
                        Naskenovaný kód:
                    </Segment>
                    <Segment inverted>
                        {this.props.barcode}
                    </Segment>
                    <Segment>
                        Pořiďte fotku..
                    </Segment>
                </Segment.Group>

                <div className='webcamDiv'>
                    <Webcam
                        ref={this.cameraRef}
                        forceScreenshotSourceSize={true}
                        screenshotQuality={1}
                        screenshotFormat={'image/jpeg'}
                        imageSmoothing={true}
                        onUserMedia={this.onUserMedia.bind(this)}
                        audio={false}
                        videoConstraints={{
                            facingMode: 'environment',
                            width: { ideal: 1536 },
                            height: { ideal: 1936 },
                            aspectRatio: { ideal: 1 }
                        }} />
                </div>

                <div className={'photo-buttons'}>
                    <Button circular size='massive' icon='camera' color='black' onClick={this.pictureTaken.bind(this)} disabled={this.props.disabled || this.state.pictureSaving || !this.state.cameraReady} />

                    <Button circular size='massive' icon='arrow left' color='red' onClick={() => this.props.onCancel()} disabled={this.props.disabled || this.state.pictureSaving} />
                </div>
            </div>
        </div >);
    }
}