﻿import React from 'react';

import './ErrorNotFound.css';

import { Transition, Loader, Container, Image, Icon, Segment } from 'semantic-ui-react';
import MobileAppMenuBar from './MobileAppMenuBar';

import { useAuth } from "../hooks/useAuth";

const ErrorNotFound = () => {
    const authContext = useAuth();

    return (<>
        <Container className='errorNotFound'>
            <Image src='/online-documentor-error-image.png' alt='error image' />

            <Segment inverted color='red' textAlign='center'>
                Stránka nenalezena!
            </Segment>
        </Container>

        {authContext?.user && <MobileAppMenuBar />}
    </>);
}

export default ErrorNotFound;