/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EvidenceEntry } from '../models/EvidenceEntry';
import type { EvidenceEntryPagedListResponse } from '../models/EvidenceEntryPagedListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EvidenceService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param pageNumber 
     * @param pageSize 
     * @param search 
     * @returns EvidenceEntryPagedListResponse Success
     * @throws ApiError
     */
    public getApiEvidence(
pageNumber?: number,
pageSize?: number,
search?: string,
): CancelablePromise<EvidenceEntryPagedListResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Evidence',
            query: {
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'search': search,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns EvidenceEntry Success
     * @throws ApiError
     */
    public postApiEvidence(
requestBody?: EvidenceEntry,
): CancelablePromise<EvidenceEntry> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Evidence',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @param itemId 
     * @returns binary Success
     * @throws ApiError
     */
    public getApiEvidenceItemsImage(
id: string,
itemId: string,
): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Evidence/{id}/Items/{itemId}/Image',
            path: {
                'id': id,
                'itemId': itemId,
            },
        });
    }

    /**
     * @param id 
     * @returns EvidenceEntry Success
     * @throws ApiError
     */
    public getApiEvidence1(
id: string,
): CancelablePromise<EvidenceEntry> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Evidence/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public putApiEvidence(
id: string,
requestBody?: EvidenceEntry,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Evidence/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns EvidenceEntry Success
     * @throws ApiError
     */
    public deleteApiEvidence(
id: string,
): CancelablePromise<EvidenceEntry> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/Evidence/{id}',
            path: {
                'id': id,
            },
        });
    }

}
