import React from 'react';
import { PropsWithChildren } from 'react';

export type BarcodeScannerErrorProps = {
    children: any 
};

export type BarcodeScannerErrorState = {
    errorFound: boolean
};

export default class BarcodeScannerError extends React.Component<BarcodeScannerErrorProps, BarcodeScannerErrorState> {
    constructor(props: BarcodeScannerErrorProps) {
        super(props);
        this.state = {
            errorFound: false
        };
    }

    componentDidCatch(error: Error, info: { componentStack: string, }) {
        this.setState({
            errorFound: true
        });
        console.log('error: ', error);
        console.log('info: ', info);
    }

    render () {
        if (this.state.errorFound) {
            // TODO: check if error is recoverable
            return <>{this.props.children}</>;
        }
        
        return <>{this.props.children}</>;
    }
}