﻿import React from 'react';
    
import './NotificationBar.css';

import axios from 'axios';

import { Transition, Loader, Container, Icon, IconGroup } from 'semantic-ui-react';

import eventBus from "../helpers/EventBus.jsx";
import { version } from './../../package.json';
import { Helmet } from 'react-helmet';

export type NotificationBarProps = {

};

export type NotificationBarState = {
    showSuccess: boolean,
    successText: string,
    showError: boolean,
    errorText: string,
    showWarning: boolean,
    warningText: string,
};

export default class NotificationBar extends React.Component<NotificationBarProps, NotificationBarState> {
    constructor(props: NotificationBarProps) {
        super(props);

        this.state = {
            showSuccess: false,
            successText: "",
            showError: false,
            errorText: "",
            showWarning: false,
            warningText: ""
        };
    }

    componentDidMount() {
        eventBus.on("showError", (e: CustomEvent) => {
            this.setState({
                showError: true,
                errorText: e.detail
            });
        });
        eventBus.on("showSuccess", (e: CustomEvent<any>) => {
            this.setState({
                showSuccess: true,
                successText: e.detail as string
            });
        });
        eventBus.on("showWarning", (e: CustomEvent) => {
            this.setState({
                showWarning: true,
                warningText: e.detail as string
            });
        });
    }

    onSuccessAnimationFinish() {
        setTimeout(() => this.setState({ showSuccess: false }), 5000);
    }

    onErrorAnimationFinish() {
        setTimeout(() => this.setState({ showError: false }), 5000);
    }

    onWarningAnimationFinish() {
        setTimeout(() => this.setState({ showWarning: false }), 5000);
    }

    render() {
        return (
            <div>
                <Helmet>
                    {this.state.showSuccess && <meta name="theme-color" content="#228b22" />}
                    {this.state.showError && <meta name="theme-color" content="#8b0000" />}
                    {this.state.showWarning && <meta name="theme-color" content="#ff8c00" />}

                </Helmet>
                <Transition visible={this.state.showSuccess}
                    onShow={this.onSuccessAnimationFinish.bind(this)}
                    duration={250}
                    animation='slide down'>
                    <div className='notification successNotification'>
                        <p className='notificationText'>{this.state.successText}</p>
                    </div>
                </Transition>

                <Transition visible={this.state.showError}
                    onShow={this.onErrorAnimationFinish.bind(this)}
                    duration={250}
                    animation='slide down'>
                    <div className='notification errorNotification'>
                        <p className='notificationText'>{this.state.errorText}</p>
                    </div>
                </Transition>

                <Transition visible={this.state.showWarning}
                    onShow={this.onWarningAnimationFinish.bind(this)}
                    duration={250}
                    animation='slide down'>
                    <div className='notification warningNotification'>
                        <p className='notificationText'>{this.state.warningText}</p>
                    </div>
                </Transition>
            </div>
        );
    }
}