import React, { Component, useEffect } from 'react';
import { useRegisterSW } from 'virtual:pwa-register/react';

import {
    useRoutes,
    Outlet,
    useNavigate
} from "react-router-dom";

import PhotoDocumentation from './pages/PhotoDocumentation';
import ErrorNotFound from './components/ErrorNotFound';
import Version from './components/Version';
import NotificationBar from './components/NotificationBar';
import MobileAppMenuBar from './components/MobileAppMenuBar';
import Home from './pages/Home';
import { DEFAULTPAGESTORAGEKEY, PROMPTCHATREFRESHTOKEN } from './constants/LocalStorageKeys';
import NavigateToDefaultPage from './helpers/NavigateToDefaultPage';
import Login from './pages/Login';
import AccountIndex from './pages/Account/AccountIndex';
import MobileMenu from './pages/MobileMenu';
import { Button, Container, Message } from 'semantic-ui-react';

import PrivateRoute from './PrivateRoute';

import { AuthProvider, useAuth } from "./hooks/useAuth";
import PhotoDocumentationEvidence from './pages/PhotoDocumentationEvidence';
import PhotoDocumentationEvidenceItem from './pages/PhotoDocumentationEvidenceItem';
import CompanyStorageDestinationSettings from './pages/Company/CompanyStorageDestinationSettings';
import AccountPasswordChange from './pages/Account/AccountPasswordChange';
import CreateAccount from './pages/CreateAccount';
import AccountForgottenPasswordRequest from './pages/Account/AccountForgottenPasswordRequest';
import AccountForgottenPasswordCompletion from './pages/Account/AccountForgottenPasswordCompletion';
import { useLocalStorage } from './hooks/useLocalStorage';
import axios from 'axios';
import { AppUser, AuthenticateResponse, StorageDestinationTypes } from './services/generatedApi';
import UserService from './services/UserService';
import PWAPrompt from 'react-ios-pwa-prompt'

const StorageDestinationCheck: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const authContext = useAuth();
    const navigate = useNavigate();

    return (<>
        {(authContext?.user?.company?.companyStorageDestination?.storageDestinationType ?? StorageDestinationTypes.NONE) === StorageDestinationTypes.NONE && <Container>
            <Message error>
                <Message.Header>Chybějící nastavení!</Message.Header>
                <p>Pro pokračování je nutné nastavit úložiště.</p>
                <Container textAlign='center'>
                    <Button color='black' onClick={() => navigate('/Company/StorageDestinationSettings')}>
                        Nastavení úložiště
                    </Button>
                </Container>
            </Message>
        </Container>}
        {(authContext?.user?.company?.companyStorageDestination?.storageDestinationType ?? StorageDestinationTypes.NONE) !== StorageDestinationTypes.NONE && children}
    </>);
};

function App() {
    useRegisterSW({
        immediate: true
    });

    // Use promptchat refresh token

    async function usePromptchatToken(promptchatRefreshToken: string) {
        try {
            const rs = await axios.post<AuthenticateResponse>("api/Users/refresh-token", { "refreshToken": promptchatRefreshToken });
            UserService.updateLocalAccessToken(rs.data);

            const user = await axios.get<AppUser>("api/User/", {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${rs.data.accessToken}`
                }
            })
            UserService.setUser(user.data);
            localStorage.removeItem(PROMPTCHATREFRESHTOKEN);
            window.location.replace('/');
        } catch {

        }
    }


    useEffect(() => {
        // later remove, when all existing clients have been restored
        const promptchatRefreshToken = localStorage.getItem(PROMPTCHATREFRESHTOKEN);

        if (promptchatRefreshToken && !UserService.getLocalAccessToken()) {
            usePromptchatToken(promptchatRefreshToken);
        }
    }, []);

    const Routes = () => useRoutes([{
        path: "/",
        element: <PrivateRoute>
            <NavigateToDefaultPage />
            <Outlet />
            <MobileAppMenuBar />
        </PrivateRoute>,
        errorElement: <>
            <ErrorNotFound />
            <MobileAppMenuBar />
        </>,
        children: [
            {
                path: "/",
                element: <Home />,

            },
            {
                path: "/PhotoDocumentation",
                element: <StorageDestinationCheck><PhotoDocumentation /></StorageDestinationCheck>,
            },
            {
                path: "/PhotoDocumentation/Evidence",
                element:
                    <PhotoDocumentationEvidence />
                ,
            },
            {
                path: "/PhotoDocumentation/Evidence/:id",
                element: <PhotoDocumentationEvidenceItem />,
            },
            {
                path: "/Account",
                element: <AccountIndex />,
            },
            {
                path: "/Account/PasswordChange",
                element: <AccountPasswordChange />,
            },
            {
                path: "/Company/StorageDestinationSettings",
                element: <CompanyStorageDestinationSettings />
            },
            {
                path: "/MobileMenu",
                element: <MobileMenu />,
            },
        ]
    },
    {
        path: "/Login",
        element: <>
            <Login />
        </>
    },

    // Account creation route
    {
        path: "/CreateAccount",
        element: <CreateAccount />
    },

    // Password reset routes
    {
        path: "/Account/ForgottenPassword/Request",
        element: <AccountForgottenPasswordRequest />
    },
    {
        path: "/Account/ForgottenPassword/Complete",
        element: <AccountForgottenPasswordCompletion />
    },

    // Fallback route when not found and not logged in
    {
        path: "*",
        element: <>
            <ErrorNotFound />
        </>
    }
    ]);

    return (<AuthProvider>
        <PWAPrompt promptOnVisit={1} timesToShow={3}
            copyTitle='Instalace'
            copyBody='Tato webová stránka má funkci aplikace. Přidejte si ji na domovskou obrazovku a používejte ji na celé obrazovce i v režimu offline.'
            copyShareButtonLabel='1) Stiskněte tlačítko "Sdílet".'
            copyAddHomeButtonLabel='2) Stiskněte tlačítko "Přidat na domovskou obrazovku"'
            copyClosePrompt='Zavřít'/>
        <NotificationBar />
        <Routes />
    </AuthProvider>);
}

export default App;
