/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DigieshopService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param filter 
     * @returns any Success
     * @throws ApiError
     */
    public getApiDigiEshop(
filter: string,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/digi-eshop/{filter}',
            path: {
                'filter': filter,
            },
        });
    }

}
