/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppUser } from '../models/AppUser';
import type { PasswordChangeDTO } from '../models/PasswordChangeDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns AppUser Success
     * @throws ApiError
     */
    public getApiUser(): CancelablePromise<AppUser> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/User',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public postApiUserChangePassword(
requestBody?: PasswordChangeDTO,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/User/ChangePassword',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
