import React, { useState, useCallback, useEffect } from 'react';
import { Menu, Icon, MenuItemProps, Popup, Image } from 'semantic-ui-react';
import { useLocation, useNavigate } from "react-router-dom";

import './MobileAppMenuBar.css';

const MobileAppMenuBar: React.FC<any> = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [activeItem, setActiveItem] = useState(location.pathname);

    const handleItemClick = useCallback((a: React.MouseEvent<HTMLAnchorElement, MouseEvent>, item: MenuItemProps) => {
        if (item.name !== undefined) {
            navigate(item.name);
        }
    }, []);

    useEffect(() => {
        setActiveItem(location.pathname);
    }, [location]);

    return (<Menu widths={5} icon='labeled' size='small' fixed='bottom' inverted style={{ background: 'linear-gradient(135deg, rgba(34, 243, 255, 1) 0%, rgba(95, 179, 255, 1) 100%)' }}>
        <Menu.Item
            name='/'
            active={activeItem === '/'}
            onClick={handleItemClick}>
            <Icon name='home' />
            Domů
        </Menu.Item>

        <Menu.Item
            name='/Account'
            active={activeItem === '/Account'}
            onClick={handleItemClick}>
            <Icon name='user' />
            Účet
        </Menu.Item>

        <Menu.Item
            name='/PhotoDocumentation'
            active={activeItem === '/PhotoDocumentation'}
            onClick={handleItemClick}>
            {/*<Icon name='camera' />*/}
            <Image src='/images/icon-no-background.png' size='mini' />
            Focení zásilek
        </Menu.Item>

        <Menu.Item
            name='/PhotoDocumentation/Evidence'
            active={activeItem === '/PhotoDocumentation/Evidence'}
            onClick={handleItemClick}>
            <Icon name='paperclip' />
            Evidence
        </Menu.Item>

        <Menu.Item
            name='/MobileMenu'
            active={activeItem === '/MobileMenu'}
            onClick={handleItemClick}>
            <Icon name='th' />
            Menu
        </Menu.Item>
    </Menu>);
}

export default MobileAppMenuBar;