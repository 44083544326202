/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FeedFilter } from '../models/FeedFilter';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FeedFiltersService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns FeedFilter Success
     * @throws ApiError
     */
    public getApiFeedFilters(): CancelablePromise<Array<FeedFilter>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/FeedFilters',
        });
    }

    /**
     * @param requestBody 
     * @returns FeedFilter Success
     * @throws ApiError
     */
    public postApiFeedFilters(
requestBody?: FeedFilter,
): CancelablePromise<FeedFilter> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/FeedFilters',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns FeedFilter Success
     * @throws ApiError
     */
    public getApiFeedFilters1(
id: number,
): CancelablePromise<FeedFilter> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/FeedFilters/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public putApiFeedFilters(
id: number,
requestBody?: FeedFilter,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/FeedFilters/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns FeedFilter Success
     * @throws ApiError
     */
    public deleteApiFeedFilters(
id: number,
): CancelablePromise<FeedFilter> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/FeedFilters/{id}',
            path: {
                'id': id,
            },
        });
    }

}
