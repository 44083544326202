import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.js'

import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './index.css'

import 'semantic-ui-less/semantic.less';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <Helmet>
            <meta name="theme-color" content="#E6E1E1" />
        </Helmet>

        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>)
