import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, List, Container, Image, Pagination, Grid, Search, Divider, Card, Header, CardContent, Icon, Form, Dropdown, DropdownItemProps, InputOnChangeData } from 'semantic-ui-react';

import instance from '../../services/api';
import { ApiError, EvidenceEntryPagedListResponse, PasswordChangeDTO, StorageDestinationTypes } from '../../services/generatedApi';
import debounce from 'lodash.debounce';
import { useNavigate } from 'react-router-dom';

import eventBus from '../../helpers/EventBus';

const AccountPasswordChange: React.FC<any> = (props) => {
    const navigate = useNavigate();
    const [passwordChangeModel, setPasswordChangeModel] = useState<PasswordChangeDTO>();

    const [currentPasswordError, setCurrentPasswordError] = useState<{ content: string, location?: string }>();
    const [newPasswordError, setNewPasswordError] = useState<{ content: string, location?: string }>();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => setPasswordChangeModel(prevState => ({
        ...prevState,
        [data.name]: data.value
    }));

    async function handlePasswordChange() {
        try {
            await instance.user.postApiUserChangePassword(passwordChangeModel);
            eventBus.dispatch("showSuccess", "Heslo úspěšně změněno");

            navigate(-1);
        } catch (e) {
            const apiError = e as ApiError;
            if (apiError) {
                if (apiError.status === 403) {
                    setCurrentPasswordError({ content: 'Zadané heslo není správné' });
                }
            } else {
                eventBus.dispatch("showError", "Chyba při změně hesla, zkuste to prosím znovu");

            }
        }
    }

    // When current password changes, clear error
    useEffect(() => {
        setCurrentPasswordError(undefined);
    }, [passwordChangeModel?.currentPassword]);

    // Check that new password and new password again match
    useEffect(() => {
        if (passwordChangeModel?.newPassword && passwordChangeModel.newPasswordAgain) {
            if (passwordChangeModel.newPassword !== passwordChangeModel.newPasswordAgain) {
                return setNewPasswordError({ content: 'Hesla se neshodují' });
            }
        }

        setNewPasswordError(undefined);
    }, [passwordChangeModel?.newPassword, passwordChangeModel?.newPasswordAgain]);

    return (
        <Container className='gradientContainer noStandaloneOffset' style={{ borderRadius: '1em', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Container>
                <Divider hidden />

                <Header inverted size='huge' textAlign='left' style={{ marginBottom: '0', marginTop: '0', fontSize: '3em', textShadow: '0px 0px 6px rgba(0,0,0,0.4)', marginLeft: '.5em' }}>
                    Změna hesla
                    <Header.Subheader style={{ textShadow: '0 0' }}>

                    </Header.Subheader>
                </Header>
            </Container>

            <Container fluid style={{
                height: 'auto', backgroundColor: '#E6E1E1', flexGrow: '1', display: 'flex', flexDirection: 'column',
                borderRadius: '2em', paddingBottom: '80px', overflow: 'auto'
            }} >
                <Container textAlign='center'>
                    <Divider hidden />
                    <Icon.Group size='massive'>
                        <Icon name='shield alternate' color='black' />
                        <Icon name='lock' size='mini' color='black' />
                    </Icon.Group>
                    <Header>
                        Přejete si změnit heslo?
                        <Header.Subheader>
                            Zadejte svoje původní heslo, nové heslo a následně pro kontrolu ho zopakujte
                        </Header.Subheader>
                    </Header>

                    <Form>
                        <Form.Input fluid iconPosition='left' placeholder='Původní heslo'
                            type='password' name='currentPassword' onChange={handleChange}
                            value={passwordChangeModel?.currentPassword}
                            autoComplete='current-password'
                            required
                            error={currentPasswordError} />
                    </Form>
                    <Divider />

                    <Form autoComplete='new-password'>
                        <Form.Input fluid iconPosition='left' placeholder='Nové heslo'
                            type='password' name='newPassword' onChange={handleChange}
                            value={passwordChangeModel?.newPassword}
                            required />
                        <Form.Input fluid iconPosition='left' placeholder='Zadejte znovu nové heslo'
                            type='password' name='newPasswordAgain' onChange={handleChange}
                            value={passwordChangeModel?.newPasswordAgain}
                            required
                            error={newPasswordError} />

                        <Form.Button fluid icon='save outline' primary content='Uložit' onClick={() => handlePasswordChange()} />
                    </Form>
                </Container>

            </Container>
        </Container >);
}

export default AccountPasswordChange;