﻿import React, { RefObject, useEffect } from 'react';

import './PhotoDocumentation.css';

import { Transition, Container, Step, Icon, Divider, Message, Button } from 'semantic-ui-react';

import BarcodeScanner from '../components/BarcodeScanner';
import BarcodeScannerError from '../components/BarcodeScannerError';
import PhotoCreation from '../components/PhotoTaker/PhotoCreation';
import PhotoConfirmation from '../components/PhotoTaker/PhotoConfirmation';

import { useAuth } from '../hooks/useAuth';
import { StorageDestinationTypes } from '../services/generatedApi';
import { useNavigate } from 'react-router-dom';


export enum PhotoDocumentationStages {
    None,
    TransitionToBarcodeScan,
    BarcodeScan,
    TransitionToPhotoCreation,
    PhotoCreation,
    TransitionToPhotoConfirmation,
    PhotoConfirmation,
    PhotoUpload
}

export type PhotoDocumentationProps = {
};

export type PhotoDocumentationState = {
    width: number,
    scannedBarcode: string,
    images: string[],

    takingPicture: boolean,

    stage: PhotoDocumentationStages,

    backPressed: boolean,
    isCurrentPage: boolean
};

export default class PhotoDocumentation extends React.Component<any, PhotoDocumentationState> {

    constructor(props: any) {
        super(props);
        this.state = {
            width: window.innerWidth,
            scannedBarcode: "",
            images: [],
            takingPicture: false,

            stage: PhotoDocumentationStages.BarcodeScan,

            backPressed: false,
            isCurrentPage: true
        };
    }

    checkUrlMatching() {
        if ((window.location.pathname == '/PhotoDocumentation') != this.state.isCurrentPage) {
            this.setState({
                isCurrentPage: window.location.pathname == '/PhotoDocumentation'
            });
            console.log('Url matching: ' + this.state.isCurrentPage);
        }
    }

    barcodeConfirmClick(barcode: string) {
        this.setState({
            scannedBarcode: barcode,
            stage: PhotoDocumentationStages.TransitionToPhotoCreation,
        });
    }

    photoTakerSuccess() {
        this.goToStart();
    }

    goToStart() {
        this.setState({
            stage: PhotoDocumentationStages.TransitionToBarcodeScan,
            scannedBarcode: "",
            images: []
        });
    }

    removePhoto(photoIndex: number) {
        var array = [...this.state.images];

        array.splice(photoIndex, 1);
        this.setState({
            images: array
        });
    }

    handleStageChange() {
        switch (this.state.stage) {
            case PhotoDocumentationStages.TransitionToBarcodeScan:
                this.setState({ stage: PhotoDocumentationStages.BarcodeScan });
                break;
            case PhotoDocumentationStages.TransitionToPhotoCreation:
                this.setState({ stage: PhotoDocumentationStages.PhotoCreation });
                break;
            case PhotoDocumentationStages.TransitionToPhotoConfirmation:
                this.setState({ stage: PhotoDocumentationStages.PhotoConfirmation });
                break;
            default:
                break;
        }
    }

    render() {
        return (<>
            <Container textAlign='center'>
                <Step.Group size='mini' unstackable fluid >
                    <Step active={this.state.stage <= PhotoDocumentationStages.BarcodeScan}
                        completed={this.state.stage > PhotoDocumentationStages.BarcodeScan}>
                        <Icon name='barcode' size='tiny' style={{ fontSize: '1.25em' }} />
                        <Step.Content>
                            <Step.Title>Skenování</Step.Title>
                            {/*<Step.Description>Načtěte čárový kód</Step.Description>*/}
                        </Step.Content>
                    </Step>

                    <Step active={(this.state.stage > PhotoDocumentationStages.TransitionToPhotoCreation && this.state.stage < PhotoDocumentationStages.PhotoUpload)}
                        completed={this.state.stage > PhotoDocumentationStages.PhotoConfirmation}>
                        <Icon name='photo' size='tiny' style={{ fontSize: '1.25em' }} />
                        <Step.Content>
                            <Step.Title>Focení</Step.Title>
                            {/*<Step.Description>Zdokumentujte zásilku</Step.Description>*/}
                        </Step.Content>
                    </Step>

                    <Step active={this.state.stage === PhotoDocumentationStages.PhotoUpload}>
                        <Icon name='upload' size='tiny' style={{ fontSize: '1.25em' }} />
                        <Step.Content>
                            <Step.Title>Ukládání</Step.Title>
                            {/*<Step.Description>Systém uloží dokumentaci</Step.Description>*/}
                        </Step.Content>
                    </Step>
                </Step.Group>
            </Container>
            <Divider hidden fitted /><Divider hidden fitted /><Divider hidden fitted /><Divider hidden fitted />

            <Container {...this.props} textAlign='center' style={{ height: 'calc(100% - 50px - 80px)' }}>
                <BarcodeScannerError>
                    <Transition animation="fade" duration={250} unmountOnHide={true}
                        visible={this.state.stage === PhotoDocumentationStages.BarcodeScan}
                        onHide={this.handleStageChange.bind(this)}>
                        <BarcodeScanner onConfirm={this.barcodeConfirmClick.bind(this)} showButtons={true} />
                    </Transition>
                </BarcodeScannerError>

                <Transition animation="fade" duration={250} unmountOnHide={true}
                    visible={this.state.stage === PhotoDocumentationStages.PhotoCreation}
                    onHide={() => this.setState({
                        stage: this.state.backPressed && this.state.images.length == 0 ? PhotoDocumentationStages.BarcodeScan : PhotoDocumentationStages.PhotoConfirmation,
                        backPressed: false
                    })}>
                    <PhotoCreation barcode={this.state.scannedBarcode} 
                        onPictureTaken={(image: string) => this.setState({ images: this.state.images.concat(image), stage: PhotoDocumentationStages.TransitionToPhotoConfirmation })}
                        onCancel={() => this.setState({ backPressed: true, stage: PhotoDocumentationStages.TransitionToPhotoConfirmation })} />
                </Transition>

                <Transition animation="fade" duration={250}
                    visible={this.state.stage >= PhotoDocumentationStages.PhotoConfirmation}
                    unmountOnHide={true}
                    onHide={this.handleStageChange.bind(this)}>
                    <PhotoConfirmation
                        onStartUpload={() => this.setState({ stage: PhotoDocumentationStages.PhotoUpload })}
                        images={this.state.images}
                        barcode={this.state.scannedBarcode}
                        onCancel={this.goToStart.bind(this)}
                        onSuccess={this.goToStart.bind(this)}
                        onPhotoRemove={this.removePhoto.bind(this)}
                        onPhotoAdd={() => this.setState({ stage: PhotoDocumentationStages.TransitionToPhotoCreation })} />
                </Transition>
            </Container>
        </>);
    }
}