import React from 'react';

import './PhotoContainer.css';

import { Button, Container } from 'semantic-ui-react';

export type PhotoContainerProps = {
    className: string,
    image: string,
    disabled?: boolean,
    onRemove: Function
};

export default class PhotoContainer extends React.Component<PhotoContainerProps> {

    render() {
        const { onRemove, ...props } = this.props;

        return (
            <Container {...props}>
                <img src={this.props.image} alt='camera'></img>
                <div className='removePhoto'>
                    <Button circular icon='close' color='grey' onClick={this.props.onRemove.bind(this)} disabled={this.props.disabled} />
                </div>
            </Container>
        )
    }
}