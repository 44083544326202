import React, { RefObject } from 'react';

import './PhotoConfirmation.css';

import { Button, Segment, Loader, Transition, Dimmer } from 'semantic-ui-react';

import axios from 'axios';

import eventBus from '../../helpers/EventBus';

import PhotoContainer from './PhotoContainer';
import { addInterceptors } from '../../services/api';

export type PhotoConfirmationProps = {
    barcode: string,
    images: string[],
    onStartUpload: Function,
    onSuccess: Function,
    onCancel: Function,
    onPhotoAdd: Function,
    onPhotoRemove: Function
};

export type PhotoConfirmationState = {
    pictureUploading: boolean,

};

export default class PhotoConfirmation extends React.Component<PhotoConfirmationProps, PhotoConfirmationState> {
    private photosRef: RefObject<HTMLDivElement> = React.createRef();

    constructor(props: any) {
        super(props);
        this.photosRef = React.createRef();

        this.state = {
            pictureUploading: false
        }
    }

    componentDidUpdate() {
        if (this.photosRef.current != null) {
            this.photosRef.current.scrollLeft = this.photosRef.current?.scrollWidth;
        }
    }

    uploadPicture() {
        var formData = new FormData();
        formData.append("name", this.props.barcode);
        this.props.images.forEach((image) => formData.append("images", this.dataURItoBlob(image)));

        this.props.onStartUpload();
        this.setState({
            pictureUploading: true
        });
        let axiosInstance = axios.create();
        addInterceptors(axiosInstance);
        axiosInstance.post('api/uploadImage', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            this.props.onSuccess();
            eventBus.dispatch("showSuccess", "Fotka byla nahrána");
        }).catch((err) => {
            eventBus.dispatch("showError", "Chyba při nahrávání fotky!");
            this.setState({
                pictureUploading: false
            });
        });
    }

    dataURItoBlob(dataURI: string) {
        var binary = atob(dataURI.split(',')[1]);
        var array = [];
        for (var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
    }

    render() {
        const { onCancel, onSuccess, onStartUpload, onPhotoRemove, onPhotoAdd, ...props } = this.props;

        return (
            <div {...props} style={{ height: '100%' }}>
                <Segment.Group>
                    <Segment color='blue'>
                        Naskenovaný kód:
                    </Segment>
                    <Segment inverted>
                        {this.props.barcode}
                    </Segment>
                    <Segment>
                        {`Chcete fotk${this.props.images.length > 1 ? 'y' : 'u'} uložit?`}
                    </Segment>
                </Segment.Group>

                <div className='confirmingDiv' ref={this.photosRef}>
                    <Transition.Group
                        animation='scale'
                        duration={250}>
                        {this.props.images.map((value, index) => {
                            return <PhotoContainer
                                className='photoContainer'
                                key={index}
                                image={value}
                                disabled={this.state.pictureUploading}
                                onRemove={() => this.props.onPhotoRemove(index)} />;
                        })}
                    </Transition.Group>


                    <div className='addPhoto'>
                        <Button circular size='massive' icon='add' color='black' onClick={this.props.onPhotoAdd.bind(this)} disabled={this.state.pictureUploading} />
                    </div>
                </div>



                {this.state.pictureUploading ?
                    <Dimmer active>
                        <Loader active inverted inline='centered'>{`Nahrávám fotk${this.props.images.length > 1 ? 'y' : 'u'}...`}</Loader>
                    </Dimmer>
                    : null}

                <div className={'photo-buttons'}>
                    <Button circular size='massive' icon='check' color='green'
                        onClick={this.uploadPicture.bind(this)}
                        disabled={this.state.pictureUploading || this.props.images.length < 1} />
                    <Button circular size='massive' icon='close' color='red'
                        onClick={this.props.onCancel.bind(this)}
                        disabled={this.state.pictureUploading} />
                </div>
            </div>
        );
    }
}