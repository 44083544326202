import React, { ReactNode } from "react";

import { Navigate } from "react-router-dom";
import { useAuth } from "./hooks/useAuth";

interface Props {
    children?: ReactNode
    // any props that come into the component
}

const PrivateRoute = ({ children, ...props } : Props) => {
    const authContext = useAuth();

    if (!authContext?.user) {
        // user is not authenticated
        return <Navigate to="/Login" />;
    }
    return children;
};

export default PrivateRoute;