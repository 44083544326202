import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, List, Container, Image, Pagination, Grid, Search, Divider, Card, Header, CardContent, Icon, Form, Dropdown, DropdownItemProps } from 'semantic-ui-react';

import instance from '../../services/api';
import { EvidenceEntryPagedListResponse, StorageDestinationTypes } from '../../services/generatedApi';
import debounce from 'lodash.debounce';
import { useNavigate } from 'react-router-dom';

const AccountForgottenPasswordCompletion: React.FC<any> = (props) => {
    const navigate = useNavigate();

    return (
        <Container className='gradientContainer noStandaloneOffset' style={{ borderRadius: '1em', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Container>
                <Divider hidden />

                <Header inverted size='huge' textAlign='left' style={{ marginBottom: '0', marginTop: '0', fontSize: '3em', textShadow: '0px 0px 6px rgba(0,0,0,0.4)', marginLeft: '.5em' }}>
                    Obnovení hesla
                    <Header.Subheader style={{ textShadow: '0 0' }}>

                    </Header.Subheader>
                </Header>
            </Container>

            <Container fluid style={{
                height: 'auto', backgroundColor: '#E6E1E1', flexGrow: '1', display: 'flex', flexDirection: 'column',
                borderRadius: '2em', paddingBottom: '80px', overflow: 'auto'
            }} >
                <Container>
                    <Icon.Group size='massive'>
                        <Icon name='shield alternate' />
                        <Icon name='lock' />
                    </Icon.Group>

                    <Header>
                        Zadejte nové heslo
                    </Header>

                    <Form autoComplete='new-password'>
                        <Form.Input fluid iconPosition='left' placeholder='Nové heslo' type='password' />
                        <Form.Input fluid iconPosition='left' placeholder='Zadejte znovu nové heslo' type='password' />

                        <Form.Button fluid icon='save outline' primary content='Uložit' />
                    </Form>

                    {/*<Když není v url kod pro obnovu/>*/}
                    <Header>
                        Zadejte kód obnovení

                        <Header.Subheader>
                            V dalším kroku provedete změnu hesla
                        </Header.Subheader>
                    </Header>

                    <Form autoComplete='new-password'>
                        <Form.Input fluid iconPosition='left' placeholder='Kód obnovení' />

                        <Form.Button fluid icon='save outline' primary content='Pokračovat' />
                    </Form>
                </Container>

                <Divider hidden />
            </Container>
        </Container >);
}

export default AccountForgottenPasswordCompletion;