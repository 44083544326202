/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClientAppService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any Success
     * @throws ApiError
     */
    public getApiClientAppVersion(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ClientApp/version',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public getApiClientAppPremier(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ClientApp/premier',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public getApiClientAppPublicIp(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ClientApp/publicIp',
        });
    }

}
