import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, List, Container, Image, Pagination, Grid, Search, Divider, Card, Header, CardContent, Icon, Form, Dropdown, DropdownItemProps, Message, InputOnChangeData, DropdownProps, Loader, Dimmer } from 'semantic-ui-react';

import instance from '../../services/api';
import { EvidenceEntryPagedListResponse, StorageDestination, StorageDestinationTypes, UpdateStorageDestinationDTO } from '../../services/generatedApi';
import debounce from 'lodash.debounce';
import { useNavigate } from 'react-router-dom';
import eventBus from '../../helpers/EventBus';
import { useAuth } from '../../hooks/useAuth';

const CompanyStorageDestinationSettings: React.FC<any> = (props) => {
    const navigate = useNavigate();
    const authContext = useAuth();

    const [companyStorageSettings, setCompanyStorageSettings] = useState<StorageDestination | undefined>(undefined);
    const [updateStorageDestination, setUpdateStorageDestination] = useState<UpdateStorageDestinationDTO | undefined>(undefined);

    const [testInProgress, setTestInProgress] = useState<Boolean>(false);

    async function fetchCompanyStorageSettings() {
        try {
            let response = await instance.company.getApiCompanyStorageDestination();

            if (response === undefined) {
                response = {};
            }
            setCompanyStorageSettings(response);
        } catch {

        }
    }

    useEffect(() => {
        fetchCompanyStorageSettings();
    }, [])

    useEffect(() => {
        if (companyStorageSettings === undefined) {
            return;
        }

        setUpdateStorageDestination({
            type: companyStorageSettings?.storageDestinationType ?? StorageDestinationTypes.NONE,
            ftpServerAddress: companyStorageSettings?.ftpStorageDestination?.ftpServerAddress,
            ftpServerUsername: companyStorageSettings?.ftpStorageDestination?.username,
            ftpServerPassword: companyStorageSettings?.ftpStorageDestination?.password,
            ftpServerBaseFolder: companyStorageSettings?.ftpStorageDestination?.baseFolder
        });
    }, [companyStorageSettings]);


    const handleStorageDestinationTypechange = (e: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => setUpdateStorageDestination(prevState => ({
        ...prevState,
        [data.name]: data.value
    }));
    const handleChangeUpdateStorageDestination = (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => setUpdateStorageDestination(prevState => ({
        ...prevState,
        [data.name]: data.value
    }));

    function storageDestinationToIcon(type: StorageDestinationTypes): string {
        switch (type) {

            case StorageDestinationTypes.FTP:
                return 'server';
            default:
                return 'question';
        }
    }

    async function handleSaveClick() {
        try {
            await instance.company.putApiCompanyStorageDestination(updateStorageDestination);
            eventBus.dispatch('showSuccess', 'Nastavení byla uložena')

            try {
                const user = await instance.user.getApiUser();
                authContext?.login(user);
            } catch (e) {
                eventBus.dispatch('showWarning', 'Nepodařilo se aktualizovat nastavení, restartujte aplikaci')
            }
        } catch (e) {
            eventBus.dispatch('showError', 'Chyba ukládání úložiště')
        }

        fetchCompanyStorageSettings();
    }

    async function handleTest() {
        try {
            setTestInProgress(true);
            await instance.company.postApiCompanyStorageDestinationTest(updateStorageDestination);
            eventBus.dispatch('showSuccess', 'Test úložiště byl úspěšný!')

        } catch (e) {
            eventBus.dispatch('showWarning', 'Nepodařilo se spojit, zkontrolujte nastavení')
        } finally {
            setTestInProgress(false);
        }
    }

    return (
        <Container className='gradientContainer noStandaloneOffset' style={{ borderRadius: '1em', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Container>
                <Divider hidden />

                <Header inverted size='huge' textAlign='left' style={{ marginBottom: '0', marginTop: '0', fontSize: '3em', textShadow: '0px 0px 6px rgba(0,0,0,0.4)', marginLeft: '.5em' }}>
                    Nastavení úložiště
                    <Header.Subheader style={{ textShadow: '0 0' }}>

                    </Header.Subheader>
                </Header>
            </Container>

            <Container fluid style={{
                height: 'auto', backgroundColor: '#E6E1E1', flexGrow: '1', display: 'flex', flexDirection: 'column',
                borderRadius: '2em', paddingBottom: '80px', overflow: 'auto'
            }} >
                <Divider hidden />

                <Container>
                    <Form>
                        <Form.Select
                            fluid
                            icon={storageDestinationToIcon(updateStorageDestination?.type ?? StorageDestinationTypes.NONE)}
                            label='Typ úložiště'
                            name='type'
                            value={updateStorageDestination?.type}
                            onChange={handleStorageDestinationTypechange}
                            disabled={updateStorageDestination === undefined}
                            options={Array.from(Object.keys(StorageDestinationTypes), (x) => {
                                let enumKey = x as keyof typeof StorageDestinationTypes;
                                let res: DropdownItemProps = {
                                    key: x, text: x, value: StorageDestinationTypes[enumKey],
                                    icon: storageDestinationToIcon(StorageDestinationTypes[enumKey])
                                };
                                return res;
                            })} />
                    </Form>
                    <Divider />

                    {updateStorageDestination === undefined && <>
                        <Loader active inline='centered' size='massive' />

                        {false && <> <Divider hidden />
                            <Button secondary onClick={() => fetchCompanyStorageSettings()}>Načíst znovu</Button>
                        </>}
                    </>}

                    {
                        updateStorageDestination?.type === StorageDestinationTypes.NONE &&
                        <Message info>
                            Je nutné zvolit typ úložiště
                        </Message>
                    }

                    {updateStorageDestination?.type === StorageDestinationTypes.FTP &&
                        <Form autoSuggest="off" autoCorrect="off" spellCheck="false" autoComplete="off">
                            <input id="fake_user_name" name="fake_user[name]" tabIndex={-1} style={{ display: 'none' }} type="text" value="my safari autofill prevent" onChange={() => { }} />
                            <Form.Input fluid iconPosition='left' icon='server'
                                name='ftpServerAddress'
                                onChange={handleChangeUpdateStorageDestination}
                                value={updateStorageDestination?.ftpServerAddress}
                                placeholder='Adresa FTP serveru' />

                            <Form.Input fluid iconPosition='left' icon='user'
                                name='ftpServerUsername'
                                onChange={handleChangeUpdateStorageDestination}
                                value={updateStorageDestination?.ftpServerUsername}
                                placeholder='FTP Uživatel' />
                            <Form.Input fluid iconPosition='left' icon='key'
                                name='ftpServerPassword'
                                onChange={handleChangeUpdateStorageDestination}
                                value={updateStorageDestination?.ftpServerPassword}
                                password='ftpPassword' placeholder='FTP Heslo'>
                                <Icon name='key' />
                                <input style={{ textSecurity: 'disc', WebkitTextSecurity: 'disc' }} />
                            </Form.Input>
                            <Form.Input fluid iconPosition='left' icon='folder'
                                name='ftpServerBaseFolder'
                                onChange={handleChangeUpdateStorageDestination}
                                value={updateStorageDestination?.ftpServerBaseFolder}
                                placeholder='Výchozí složka' />

                            <Form.Button fluid icon='save outline' primary content='Uložit' onClick={() => handleSaveClick()} />
                            <Form.Button icon='share square' color='black' content='Otestovat' onClick={() => handleTest()} />
                        </Form>
                    }
                </Container>

                <Divider hidden />
                {testInProgress && <Dimmer active>
                    <Loader active inverted inline='centered'>{`Test spojení...`}</Loader>
                </Dimmer>}
            </Container>
        </Container >);
}

export default CompanyStorageDestinationSettings;