import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, List, Container, Image, Pagination, Grid, Search, Divider, Card, Header, CardContent, Icon, Segment, GridRow, GridColumn, Placeholder, Item, PlaceholderHeader, PlaceholderLine, PlaceholderParagraph, Loader } from 'semantic-ui-react';

import instance from '../services/api';
import { EvidenceEntry, EvidenceEntryPagedListResponse } from '../services/generatedApi';
import debounce from 'lodash.debounce';
import { useNavigate } from 'react-router-dom';

const PhotoDocumentationEvidence: React.FC<any> = (props) => {
    const navigate = useNavigate();
    const scrollRef = useRef();

    const [pageSize, setPageSize] = useState<number>(10);
    const [evidenceItemsResponse, setEvidenceItemsResponse] = useState<EvidenceEntryPagedListResponse>({
        result: null,
        totalCount: undefined,
        pageSize: 10,
        currentPage: 1,
        totalPages: 0
    });
    const [responseError, setResponseError] = useState<boolean>(false);

    const [searchLoading, setSearchLoading] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>();
    const [searchResults, setSearchResults] = useState<any[]>([]);

    async function fetchEvidenceItemsList(currentPage: number) {
        setResponseError(false);
        try {
            const response = await instance.evidence.getApiEvidence(currentPage, pageSize);
            setEvidenceItemsResponse(response);

            if (scrollRef?.current) {
                (scrollRef.current as HTMLDivElement).scrollTo({ top: 0, behavior: 'smooth' });
            }
        } catch {
            setResponseError(true);
        }
    }

    useEffect(() => {
        async function fetchSearchResults() {
            setSearchLoading(true);
            try {
                if (searchValue) {
                    const response = await instance.evidence.getApiEvidence(1, 5, searchValue);
                    setSearchResults(response?.result ?? []);
                }
            } catch {

            }
            finally {
                setSearchLoading(false);
            }
        }

        fetchSearchResults()
    }, [searchValue])

    useEffect(() => {
        fetchEvidenceItemsList(evidenceItemsResponse.currentPage ?? 1);
    }, [evidenceItemsResponse.currentPage])

    return (
        <Container className='gradientContainer noStandaloneOffset' style={{ borderRadius: '1em', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Container>
                <Divider hidden />

                <Header inverted size='huge' textAlign='left' style={{ marginBottom: '0', marginTop: '0', fontSize: '3em', textShadow: '0px 0px 6px rgba(0,0,0,0.4)', marginLeft: '.5em' }}>
                    Evidence
                    <Header.Subheader style={{ textShadow: '0 0' }}>

                    </Header.Subheader>
                </Header>
            </Container>

            <Container fluid textAlign='center' style={{
                height: 'auto', backgroundColor: '#E6E1E1', flexGrow: '1', display: 'flex', flexDirection: 'column',
                borderRadius: '2em', paddingBottom: '80px', overflow: 'auto'
            }} >
                <Divider hidden />

                <Search fluid
                    disabled={(evidenceItemsResponse?.result?.length ?? 0) === 0}
                    loading={searchLoading}
                    placeholder='Search...'
                    onResultSelect={(e, data) => {
                        navigate(`${data.result.id}`);
                    }}
                    onSearchChange={(e, p) => { setSearchValue(p.value ?? '') }}
                    value={searchValue}
                    results={searchResults}
                    showNoResults
                    noResultsMessage='Nenalezeny žádné výsledky'
                    resultRenderer={(props) => <>
                        <Container fluid>
                            <Header as='h4' style={{ margin: '0' }}>
                                {props.name}
                                <Header.Subheader>
                                    <Grid fluid columns={2}>
                                        <GridColumn>
                                            {props.recordedByUser?.firstName} {props.recordedByUser?.lastName}
                                        </GridColumn>
                                        <GridColumn>
                                            {new Date(props.date ?? new Date()).toLocaleString()}
                                        </GridColumn>
                                    </Grid>
                                </Header.Subheader>
                            </Header>
                        </Container>

                    </>} />
                <Divider hidden />


                <Card.Group itemsPerRow={1} stackable ref={scrollRef}
                    style={{ flexGrow: '1', overflow: 'auto', paddingTop: '.5em', WebkitMask: 'linear-gradient(0deg,#0000,#000 2% 98%,#0000)' }}>
                    {evidenceItemsResponse?.result?.map(evidenceItem => (<Card raised className='primaryBorder' style={{ marginLeft: '1em', marginRight: '1em' }}
                        key={evidenceItem.id} onClick={() => navigate(`${evidenceItem.id}`)}>
                        <Card.Content textAlign='left'>
                            <Header>
                                <Icon name='barcode' />
                                {evidenceItem.name}
                            </Header>
                            {/*<Image src="/images/icon-no-background.png" size='mini' avatar />*/}
                            <Icon name='user' circular />
                            <span>{evidenceItem.recordedByUser?.firstName} {evidenceItem.recordedByUser?.lastName}</span>
                        </Card.Content>
                        <Card.Content extra textAlign='left'>
                            {new Date(evidenceItem.date ?? new Date()).toLocaleString()}
                        </Card.Content>
                    </Card>))}

                    {evidenceItemsResponse.totalCount === 0 && <Item extra='Nejsou k dispozici žádné položky'></Item>}
                    {evidenceItemsResponse?.result === null && <>
                        <Loader active inline='centered' size='massive' />

                        {responseError && <> <Divider hidden />
                            <Button secondary onClick={() => fetchEvidenceItemsList(evidenceItemsResponse.currentPage ?? 1)}>Načíst znovu</Button>
                        </>}
                    </>
                    }

                </Card.Group>

                <Divider hidden />

                {(evidenceItemsResponse?.totalPages ?? 0) > 1 &&
                    <Pagination
                        totalPages={evidenceItemsResponse?.totalPages ?? 0}
                        activePage={evidenceItemsResponse?.currentPage ?? 0}
                        onPageChange={(e, p) => { fetchEvidenceItemsList(p.activePage as number) }}
                        size='small'
                        ellipsisItem={null}
                        siblingRange={1}
                        style={{ alignSelf: 'center' }}
                    //// Heads up! All items are powered by shorthands, if you want to hide one of them, just pass `null` as value
                    //ellipsisItem={showEllipsis ? undefined : null}
                    //firstItem={showFirstAndLastNav ? undefined : null}
                    //lastItem={showFirstAndLastNav ? undefined : null}
                    //prevItem={showPreviousAndNextNav ? undefined : null}
                    //nextItem={showPreviousAndNextNav ? undefined : null}
                    />}

            </Container>
        </Container >);
}

export default PhotoDocumentationEvidence;