import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, List, Container, Image, Pagination, Grid, Search, Divider, Loader, Icon, Header, ImageGroup, Placeholder } from 'semantic-ui-react';
import type ImageComponent from 'semantic-ui-react';
import { useParams } from 'react-router-dom';

import { EvidenceEntry } from '../services/generatedApi';

import instance, { addInterceptors } from '../services/api';
import { type } from 'os';
import { Buffer } from 'buffer';
import axios from 'axios';

const EvidenceItemImage: React.FC<{ itemId: string | undefined, evidenceItemId: string | undefined }> = (props) => {
    const [imageSource, setImageSource] = useState<string>();
    const [imageLoadingDone, setImageLoadingDone] = useState<boolean>(false);

    async function LoadImage() {
        // when codegen implements blob, use this
        //  instance.evidence.getApiEvidenceItemsImage(props.itemId ?? '', props.evidenceItemId ?? '')
        const localInstance = axios.create();
        addInterceptors(localInstance);

        const response = await localInstance.get(`/api/Evidence/${props.itemId}/Items/${props.evidenceItemId}/Image`, { responseType: 'blob' });
        var reader = new FileReader();
        reader.onloadend = function () {
            var base64data = reader.result;

            setImageSource(base64data as string);
            setImageLoadingDone(true);
        }

        reader.readAsDataURL(response.data);
    }

    useEffect(() => {
        LoadImage();
    }, [])

    return (<>
        {imageLoadingDone && <Image size='large' centered src={imageSource} style={{ maxWidth: '80%', minHeight: '14.6em', objectFit: 'contain' }} />}
        {imageLoadingDone === false && <Placeholder className='small centered image' fluid style={{ width: '80%', height: '18em', marginTop: '0' }} ><Placeholder.Image /></Placeholder>}
    </>);
}

const PhotoDocumentationEvidenceItem: React.FC<any> = (props) => {
    const params = useParams<{ id: string | undefined }>();

    const [evidenceEntryResponse, setEvidenceEntryResponse] = useState<EvidenceEntry>();

    async function fetchEvidenceItemEntry() {
        try {
            const response = await instance.evidence.getApiEvidence1(params.id ?? '');
            setEvidenceEntryResponse(response);

        } catch {

        }
    }

    useEffect(() => {
        fetchEvidenceItemEntry();
    }, [])


    return (<Container className='gradientContainer noStandaloneOffset' style={{ borderRadius: '1em', height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Container>
            <Divider hidden />

            <Header inverted size='huge' textAlign='left' style={{ marginBottom: '0', marginTop: '0', fontSize: '3em', textShadow: '0px 0px 6px rgba(0,0,0,0.4)', marginLeft: '.5em' }}>
                Evidence
                <Header.Subheader style={{ textShadow: '0 0' }}>

                </Header.Subheader>
            </Header>
        </Container>

        <Container fluid textAlign='center'>
            <ImageGroup style={{ whiteSpace: 'nowrap', overflowY: 'auto', margin: '0', webkitMask: 'linear-gradient(90deg,#0000,#000 2% 98%,#0000)' }}>
                {evidenceEntryResponse?.evidenceEntryItems?.map(evidenceEntryItem => (<>
                    <EvidenceItemImage key={evidenceEntryItem.id} itemId={params.id} evidenceItemId={evidenceEntryItem.id} />
                </>))}
            </ImageGroup>
        </Container>

        <Container fluid style={{ height: 'auto', backgroundColor: '#E6E1E1', flexGrow: '1', borderRadius: '2em', paddingBottom: '80px' }}>
            <Container>
                <Divider hidden />

                <Header textAlign='left' size='huge' dividing style={{ marginTop: '0' }}>
                    <Icon name='barcode' />
                    {evidenceEntryResponse?.name}</Header>
                <span>Počet fotografií: <b>{evidenceEntryResponse?.evidenceEntryItems?.length}</b></span> <br></br>
                <span>Nahráno: <b>{new Date(evidenceEntryResponse?.date ?? new Date()).toLocaleString()}</b></span> <br></br>
                <span>Uživatel: <b>{evidenceEntryResponse?.recordedByUser?.firstName} {evidenceEntryResponse?.recordedByUser?.lastName}</b></span> <br></br>
                <span>Status: <b>Uloženo</b></span> <br></br>

            </Container>
        </Container>
    </Container >);
}

export default PhotoDocumentationEvidenceItem;