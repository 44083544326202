import React, { useEffect, useState } from 'react';
import { Button, Card, CardMeta, Container, Divider, Header, Icon, Image, Label, Placeholder, PlaceholderLine, Segment } from 'semantic-ui-react';

import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import instance from '../services/api';
import { CompanyDailySummary } from '../services/generatedApi';


const Home: React.FC<any> = (props) => {
    const authContext = useAuth();
    const navigate = useNavigate();
    const [companyDailySummary, setCompanyDailySummary] = useState<CompanyDailySummary>();

    useEffect(() => {
        async function fetchCompanyDailySummary() {
            try {
                const response = await instance.company.getApiCompanyDailySummary();
                setCompanyDailySummary(response);
            } catch {

            }
            finally {
            }
        }

        fetchCompanyDailySummary();
    }, []);

    function capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (<Container className='gradientContainer noStandaloneOffset' style={{ borderRadius: '1em', height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Container>
            <Header inverted size='huge' textAlign='center' style={{ marginBottom: '0', marginTop: '0', fontSize: '4em', textShadow: '0px 0px 6px rgba(0,0,0,0.4)' }}>
                Vítejte
                <Header.Subheader style={{ textShadow: '0 0' }}>
                    {capitalizeFirstLetter(new Date().toLocaleString(window.navigator.language, { weekday: 'long' }))}, {new Date().toLocaleDateString()}
                </Header.Subheader>
            </Header>

            <Header inverted textAlign='center' style={{ marginTop: '0' }}> </Header>
        </Container>


        <Container>
            <Divider hidden />
            <Segment compact>
                <Header>{authContext?.user?.company?.name}</Header>
                Přihlášen: <b>{authContext?.user?.firstName} {authContext?.user?.lastName}</b>
                <br />
                <div style={{ display: 'flex' }}>
                    <span style={{ marginRight: '.25em' }}>Dnes zaevidováno:</span>
                    {
                        companyDailySummary ?
                            <span><b>{companyDailySummary.evidencedItemsToday ?? 0 > 0 ? companyDailySummary.evidencedItemsToday : ''}</b>
                                {companyDailySummary.evidencedItemsToday == 0 ? ' žádné zásilky' : companyDailySummary.evidencedItemsToday == 1 ? ' zásilka' : companyDailySummary.evidencedItemsToday ?? 0 < 5 ? ' zásilky': ' zásilek'}</span> :
                            <Placeholder style={{ flexGrow: '1', marginLeft: '.25em' }} ><PlaceholderLine style={{ height: '15%' }} /></Placeholder>
                    }
                </div>

            </Segment>

        </Container>
        <Divider hidden />

        <Container fluid style={{ height: 'auto', backgroundColor: '#E6E1E1', flexGrow: '1', borderRadius: '2em' }}>
            <Divider hidden />
            <Container>
                <Card.Group itemsPerRow={2}>
                    <Card raised className='primary'
                        href="#" onClick={() => navigate("/PhotoDocumentation")}>
                        <Card.Content textAlign='center'>
                            <Image src="/images/icon-no-background.png" size='tiny' />
                        </Card.Content>
                        <CardMeta textAlign='center'>Evidování zásilek</CardMeta>
                    </Card>
                    <Card raised className='primary'
                        href="#" onClick={() => navigate("/MobileMenu")}>
                        <Card.Content textAlign='center'>
                            <Icon name='th' size='huge' inverted />
                        </Card.Content>
                        <CardMeta textAlign='center'>Menu</CardMeta>
                    </Card>


                    <Card raised className='primary'
                        href="#" onClick={() => navigate("/Account")}>
                        <Card.Content textAlign='center'>
                            <Icon name='user' size='huge' inverted />
                        </Card.Content>
                        <CardMeta textAlign='center'>Profil</CardMeta>
                    </Card><Card raised className='primary'
                        href="#" onClick={() => navigate("/PhotoDocumentation/Evidence")}>
                        <Card.Content textAlign='center'>
                            <Icon name='boxes' size='huge' inverted />
                        </Card.Content>
                        <CardMeta textAlign='center'>Evidence</CardMeta>
                    </Card>
                    <Card raised className='primary' style={{ visibility: 'hidden' }}>
                        <Card.Content textAlign='center'>
                            <Icon name='help' size='huge' inverted />
                        </Card.Content>
                        <CardMeta textAlign='center'>Nápověda</CardMeta>
                    </Card>


                    <Card raised className='primary' style={{ visibility: 'hidden' }}>
                        <Card.Content textAlign='center'>
                            <Icon name='phone' size='huge' inverted />
                        </Card.Content>
                        <CardMeta textAlign='center'>Podpora</CardMeta>
                    </Card>
                </Card.Group>
            </Container>
        </Container>
    </Container>);
}

export default Home;