import React, { useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { DEFAULTPAGESTORAGEKEY } from "../constants/LocalStorageKeys";


const NavigateToDefaultPage: React.FC<any> = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/') {
            let defaultPage = localStorage.getItem(DEFAULTPAGESTORAGEKEY);
            if (defaultPage) {
                navigate(defaultPage);
            }
        }
    }, []);

    return (<></>);
}

export default NavigateToDefaultPage;