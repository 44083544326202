import React, { useState } from 'react';
import { Button, Form, Message, Segment, Header, Image, Grid, Label, Container, GridRow, GridColumn, InputOnChangeData } from 'semantic-ui-react';
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import axios, { AxiosResponse, AxiosError } from 'axios';
import instance from '../services/api';
import { AuthenticateRequest, AuthenticateResponse } from '../services/generatedApi';

import eventBus from '../helpers/EventBus';

import UserService from "../services/UserService";
import { useAuth } from '../hooks/useAuth';

const Login: React.FC<any> = (props) => {
    const [loginModel, setLoginModel] = useState<AuthenticateRequest>({ email: '', password: '' });
    const navigate = useNavigate();
    const authContext = useAuth();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => setLoginModel(prevState => ({
        ...prevState,
        [data.name]: data.value
    }));

    const loginAction = async () => {
        try {
            let loginResponse: AuthenticateResponse = await instance.users.postApiUsersAuthenticate(loginModel);
            UserService.updateLocalAccessToken(loginResponse);

            const user = await instance.user.getApiUser();
            console.log(authContext);
            authContext?.login(user);

            eventBus.dispatch("showSuccess", "Přihlášeno");
        } catch (err: unknown) {
            setLoginModel(prevState => ({
                ...prevState,
                password: ''
            }))

            if (err instanceof AxiosError) {
                console.log(err)
                if (err.response!.status === 401) {
                    eventBus.dispatch("showError", "Špatné jméno nebo heslo");
                    return;
                }
            }
            console.error('Unknown login Error:');
            console.error(err);
            eventBus.dispatch("showError", "Chyba při příhlášení, zkuste to znovu");
        }
    };

    return (<Container className='gradientContainer noStandaloneOffset'>
        <Helmet>
            <meta name="theme-color" content="#000000" />
        </Helmet>
        <Grid container textAlign='center' verticalAlign='middle' style={{ height: '100%', marginTop: '0' }}>
            <GridRow>
                <GridColumn verticalAlign='top'>
                    <Image src='/images/icon-no-background.png' style={{ paddingLeft: '2em', marginTop: 'calc(env(safe-area-inset-top) + 1em)' }} centered size='medium' />

                    <div style={{ color: '#eb8000', fontSize: '3.5em', paddingTop: '.5em', fontWeight: 'bold' }}>EVIDUJ.TO</div>
                </GridColumn>
            </GridRow>


            <GridRow>
                <Grid.Column verticalAlign='bottom'>
                    <Container fluid>
                        <Form size='large' onSubmit={loginAction}>
                            <Segment>
                                <Header>
                                    Přihlaš se a eviduj s Eviduj.To!
                                </Header>

                                <Form.Input
                                    name='email'
                                    fluid
                                    icon='user'
                                    iconPosition='left'
                                    placeholder='E-mailová adresa'
                                    value={loginModel.email}
                                    onChange={handleChange} required
                                />
                                <Form.Input
                                    name='password'
                                    fluid
                                    icon='lock'
                                    iconPosition='left'
                                    placeholder='Heslo'
                                    type='password'
                                    value={loginModel.password}
                                    onChange={handleChange} required
                                />
                                <Container textAlign='right'>
                                    <a href='#' onClick={() => navigate('/Account/ForgottenPassword/Request')}>Zapomenuté heslo?</a>
                                </Container>

                                <Form.Button primary fluid circular size='large'>
                                    Přihlásit se
                                </Form.Button>

                                <Header size='small' >
                                    Nový uživatel? <a href='#' onClick={() => navigate('/CreateAccount')}>Vytvořte si účet</a>
                                </Header>
                            </Segment>
                        </Form>
                    </Container>
                </Grid.Column>
            </GridRow>
        </Grid>
    </Container>);
}

export default Login;