/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { ClientAppService } from './services/ClientAppService';
import { CompanyService } from './services/CompanyService';
import { DigieshopService } from './services/DigieshopService';
import { EvidenceService } from './services/EvidenceService';
import { FeedFiltersService } from './services/FeedFiltersService';
import { InstaxshopService } from './services/InstaxshopService';
import { LevenhukService } from './services/LevenhukService';
import { PremierService } from './services/PremierService';
import { UploadImageService } from './services/UploadImageService';
import { UserService } from './services/UserService';
import { UsersService } from './services/UsersService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class AppClient {

    public readonly clientApp: ClientAppService;
    public readonly company: CompanyService;
    public readonly digieshop: DigieshopService;
    public readonly evidence: EvidenceService;
    public readonly feedFilters: FeedFiltersService;
    public readonly instaxshop: InstaxshopService;
    public readonly levenhuk: LevenhukService;
    public readonly premier: PremierService;
    public readonly uploadImage: UploadImageService;
    public readonly user: UserService;
    public readonly users: UsersService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.clientApp = new ClientAppService(this.request);
        this.company = new CompanyService(this.request);
        this.digieshop = new DigieshopService(this.request);
        this.evidence = new EvidenceService(this.request);
        this.feedFilters = new FeedFiltersService(this.request);
        this.instaxshop = new InstaxshopService(this.request);
        this.levenhuk = new LevenhukService(this.request);
        this.premier = new PremierService(this.request);
        this.uploadImage = new UploadImageService(this.request);
        this.user = new UserService(this.request);
        this.users = new UsersService(this.request);
    }
}
