import { useEffect } from "react";


import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";

import React from "react";
import { AppUser } from "../services/generatedApi";

export interface AuthContextType {
    user: AppUser | null;
    login: (data: AppUser) => void;
    logout: () => void;
}

const AuthContext = React.createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [user, setUser] = useLocalStorage("user", null);
    const [autheticateResponse, setAuthenticateResponse] = useLocalStorage("authenticateResponse", null);
    const navigate = useNavigate();

    // call this function when you want to authenticate the user
    const login = async (data: AppUser) => {
        setUser(data);
        navigate("/");
    };

    // call this function to sign out logged in user
    const logout = () => {
        setUser(null);
        setAuthenticateResponse(null);
        navigate("/", { replace: true });
    };

    return <AuthContext.Provider value={{ user, login, logout }}> {children} </AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};