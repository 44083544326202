/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Company } from '../models/Company';
import type { CompanyDailySummary } from '../models/CompanyDailySummary';
import type { StorageDestination } from '../models/StorageDestination';
import type { UpdateStorageDestinationDTO } from '../models/UpdateStorageDestinationDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CompanyService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns Company Success
     * @throws ApiError
     */
    public getApiCompany(): CancelablePromise<Company> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Company',
        });
    }

    /**
     * @returns CompanyDailySummary Success
     * @throws ApiError
     */
    public getApiCompanyDailySummary(): CancelablePromise<CompanyDailySummary> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Company/DailySummary',
        });
    }

    /**
     * @returns StorageDestination Success
     * @throws ApiError
     */
    public getApiCompanyStorageDestination(): CancelablePromise<StorageDestination> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/Company/StorageDestination',
        });
    }

    /**
     * @param requestBody 
     * @returns StorageDestination Success
     * @throws ApiError
     */
    public putApiCompanyStorageDestination(
requestBody?: UpdateStorageDestinationDTO,
): CancelablePromise<StorageDestination> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/Company/StorageDestination',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns StorageDestination Success
     * @throws ApiError
     */
    public postApiCompanyStorageDestinationTest(
requestBody?: UpdateStorageDestinationDTO,
): CancelablePromise<StorageDestination> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Company/StorageDestination/Test',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
