/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthenticateRequest } from '../models/AuthenticateRequest';
import type { AuthenticateResponse } from '../models/AuthenticateResponse';
import type { RefreshTokenRequest } from '../models/RefreshTokenRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UsersService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody 
     * @returns AuthenticateResponse Success
     * @throws ApiError
     */
    public postApiUsersAuthenticate(
requestBody?: AuthenticateRequest,
): CancelablePromise<AuthenticateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Users/authenticate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns AuthenticateResponse Success
     * @throws ApiError
     */
    public postApiUsersRefreshToken(
requestBody?: RefreshTokenRequest,
): CancelablePromise<AuthenticateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Users/refresh-token',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public postApiUsersRevokeToken(
requestBody?: RefreshTokenRequest,
): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Users/revoke-token',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
